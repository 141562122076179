<template>
  <div>
    <section>
      <v-container>
        <v-row>
        </v-row>
        <v-row>
          <v-col cols="12" lg="12" md="12">
            <div class="text-center">

                <v-card width="50%" class="center">
                  <v-card-title class="uniColor darken-3 white--text">
                    Requisitos para Registrar un Dominio:
                  </v-card-title>
                  <v-card-text>
                    <v-list
                      dense
                      v-for="(requisito, i) in requisitos"
                      :key="'J' + i"
                    >
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon
                            color="green"
                            v-text="requisito.icon"
                          ></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-action-text
                            class="texto-negro"
                            v-text="requisito.text"
                          ></v-list-item-action-text>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                  <v-divider></v-divider>
                </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      dialog2: false,
      requisitos: [
        { text: "Ser Mayor de Edad", icon: "mdi-checkbox-marked-circle" },
        {
          text: "Que el Dominio que desea Registrar esté Disponible",
          icon: "mdi-checkbox-marked-circle",
        },
        {
          text: "Tener una cuenta de usuario",
          icon: "mdi-checkbox-marked-circle",
        },
        {
          text: "Tener una cuenta de correo electrónico",
          icon: "mdi-checkbox-marked-circle",
        },
        {
          text: "Realizar el pago correspondiente",
          icon: "mdi-checkbox-marked-circle",
        },
      ],
      dominisoNi: [
        {
          sector: "Sector General",
          dominio: ".ni",
        },
        {
          sector: "Comercial",
          dominio: ".com.ni",
        },
        {
          sector: "Educativo",
          dominio: ".edu.ni",
        },
        {
          sector: "Corporativo",
          dominio: ".co.ni",
        },
        {
          sector: "Tecnología en redes",
          dominio: ".net.ni",
        },
        {
          sector: "Internacionales",
          dominio: ".int.ni",
        },
        {
          sector: "Gobierno",
          dominio: ".gob.ni",
        },
        {
          sector: "Organizaciones",
          dominio: ".org.ni",
        },
        {
          sector: "Salud",
          dominio: ".med.ni",
        },
      ],
    };
  },
  methods: {
    scrollTop() {
      setTimeout(() => {
        window.scrollTo({
          top: 380,
        });
      }, 200);
    },
  },
};
</script>
<style>
.center {
  margin-left: 25%;
}
.texto-negro {
  color: #000 !important;
  font-size: 20px;
  text-align: left;
}
.texto {
  font-size: 12px !important;
}
.texto-negro span:hover {
  color: red !important;
}
.subtitulo {
  font-size: 28px !important;
}
.imagen {
  float: right;
  margin-right: 100px;
}
.imagen3 {
  float: left;
  margin-top: -300px;
  margin-left: 100px;
}
.imagen2 {
  float: left;
  margin-left: 100px;
}
@media (max-width: 725px) {
  .imagen {
    display: none !important;
  }
  .imagen2 {
    display: none !important;
  }
  .imagen3 {
    display: none !important;
  }
  .card-phone {
    height: 600px !important;
  }
  .card-phone2 {
    height: 620px !important;
  }
  .card-phone3 {
    height: 300px !important;
  }
  .card-phone4 {
    height: 250px !important;
  }
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon,
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn,
.theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
  color: #fff !important;
}
</style>
